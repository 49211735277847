import React, { useState } from "react";

function SetAddress({ setUprnSubmitted }) {
  const [uprn, setUprn] = useState("");
  const handleInputChange = (event) => {
    setUprn(event.target.value);
  };

  const handleSubmit = () => {
    window.localStorage.setItem("UPRN", uprn);
    setUprnSubmitted(true);
  };

  return (
    <>
      <input
        name="uprn"
        type="text"
        placeholder="Enter UPRN"
        value={uprn}
        onChange={handleInputChange}
      />
      <button onClick={handleSubmit}>Submit</button>

      <p>Test</p>
    </>
  );
}

export default SetAddress;
