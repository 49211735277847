import React, { useState, useEffect } from "react";
import SetAddress from "./SetAddress";

function App() {
  const [uprn, setUprn] = useState("");
  const [uprnSubmitted, setUprnSubmitted] = useState(false);
  const [binCalendar, setBinCalendar] = useState(null);
  const [collectionSchedule, setCollectionSchedule] = useState([]);

  useEffect(() => {
    const storedUprn = window.localStorage.getItem("UPRN");
    if (storedUprn) {
      setUprn(storedUprn);
    }
  }, [uprnSubmitted]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const fetchBinCalendar = async () => {
      if (navigator.onLine) {
        const response = await fetch(
          `https://webapps.southglos.gov.uk/Webservices/SGC.RefuseCollectionService/RefuseCollectionService.svc/getCollections/${uprn}`
        );
        const data = await response.json();
        setBinCalendar(data);
        window.localStorage.setItem("binCalendar", JSON.stringify(data));
      } else {
        const storedBinCalendar = window.localStorage.getItem("binCalendar");
        if (storedBinCalendar) {
          setBinCalendar(JSON.parse(storedBinCalendar));
        }
      }
    };

    if (uprn) {
      fetchBinCalendar();
    }
  }, [uprn]);

  useEffect(() => {
    if (binCalendar) {
      const schedule = parseBinCalendar(binCalendar);
      setCollectionSchedule(schedule);

      // Schedule notification for the next collection
      if (schedule.length > 0) {
        scheduleNotification(schedule[0].date);
      }
    }
  }, [binCalendar]);

  const parseBinCalendar = (data) => {
    const schedule = [];
    data.forEach((entry) => {
      const { C1, C2, C3, R1, R2, R3 } = entry;
      if (C1)
        schedule.push({
          date: C1,
          bins: "Black bin, garden waste, recycling, food waste",
        });
      if (C2)
        schedule.push({
          date: C2,
          bins: "Black bin, garden waste, recycling, food waste",
        });
      if (C3)
        schedule.push({
          date: C3,
          bins: "Black bin, garden waste, recycling, food waste",
        });
      if (R1) schedule.push({ date: R1, bins: "Recycling, food waste" });
      if (R2) schedule.push({ date: R2, bins: "Recycling, food waste" });
      if (R3) schedule.push({ date: R3, bins: "Recycling, food waste" });
    });

    // Convert dates to YYYY-MM-DD format for correct sorting
    schedule.forEach((item) => {
      const [day, month, year] = item.date.split("/");
      item.date = `${year}-${month}-${day}`;
    });

    // Sort the schedule by date
    schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Convert dates back to DD/MM/YYYY format for display
    schedule.forEach((item) => {
      const [year, month, day] = item.date.split("-");
      item.date = `${day}/${month}/${year}`;
    });

    return schedule;
  };

  const scheduleNotification = (nextCollectionDate) => {
    const now = new Date();
    const collectionDate = new Date(nextCollectionDate);
    const timeUntilCollection = collectionDate - now;

    // Schedule the notification for the day before
    const timeUntilNotification = timeUntilCollection - 4 * 60 * 60; // hoping 4 hours before the date will be 8pm

    if (timeUntilNotification > 0) {
      setTimeout(() => {
        new Notification("Bin Collection Reminder", {
          body: `Your next bin collection is on ${nextCollectionDate}.`,
        });
      }, timeUntilNotification);
    }
  };

  if (!uprn && !uprnSubmitted) {
    return <SetAddress setUprnSubmitted={setUprnSubmitted} />;
  }

  console.log(collectionSchedule);

  return (
    <div>
      <h1>Bin Calendar</h1>
      <p>UPRN: {uprn}</p>
      {collectionSchedule.length > 0 && (
        <div>
          <h2>Collection Schedule</h2>
          <ul>
            {collectionSchedule.map((item, index) => (
              <li key={index}>
                {item.date}: {item.bins}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
